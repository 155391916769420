import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Text } from "@src/components/Text";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import Container from "@src/components/Container";
import SEO from "@src/components/SEO";

const Title = ({ children }) => (
  <Text color="white" fontSize={[16, 24]} marginBottom={0} fontWeight={700} textAlign="center">
    {children}
  </Text>
);

const PageAITransparency = () => {
  const trContext = useContext(TranslationContext);
  const t = (path: string) => trContext.translate(`directus.page_sweepstakes.${path}`);

  const data = useStaticQuery(graphql`
    query {
      directus {
        AI_Transparency {
          Content_HTML
        }
      }
    }
  `);

  return (
    <>
      <SEO title="AI Transparency Note" noindex />
      <Container>
        <h2>ClassDojo</h2>
        <h1>AI Transparency Note</h1>
        <p>
          <i>
            How information is used in ClassDojo's AI Tools in connection with school use. For information on how
            ClassDojo uses AI Productivity Tools for its internal purposes only (e.g. for non-child user account
            security), please see our
          </i>{" "}
          <a href="https://help.classdojo.com/hc/en-us/articles/35000656900749">FAQ</a>
          <i>. </i>
        </p>
        <p>
          <i>For information on ClassDojo on AI Features used at home by parents, see </i>{" "}
          <a href="https://help.classdojo.com/hc/en-us/articles/34969247491341">here</a>
          <i>. </i>
        </p>
        <h2>
          <strong>Introduction</strong>
        </h2>
        <p>
          ClassDojo is committed to continually enhancing our platform to provide the best possible learning experience
          for our{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RW61NENJ3NBCWYEA6VJKE">
            students
          </a>{" "}
          , teachers, and families. In pursuit of this goal, we are thrilled to introduce artificial intelligence ("{" "}
          <strong>AI</strong>") technology into the ClassDojo service. ClassDojo has partnered with trusted{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">service providers</a> OpenAI and Anthropic
          to launch new AI tools aimed at improving the quality and efficiency of our ClassDojo service, and meeting the
          needs of our students, teachers, and families.{" "}
        </p>
        <p>
          Our <a href="https://ai.classdojo.com/">innovative features</a> include tools to assist teachers in creating
          engaging content, deliver more effective communications, and more. Notably, these exciting new tools will:
        </p>
        <ul>
          <li>
            help teachers craft engaging group communications, such as for ClassDojo Story posts, including AI-enhanced
            narrative suggestions;
          </li>
          <li>
            enable teachers to draft and exchange more concise, effective communications with parents, colleagues and
            staff;
          </li>
          <li>
            aid teachers in creating educational content, such as lesson plans, group exercises or multiple choice
            assessments based on specified educational standards;
          </li>
          <li>
            help teachers better personalize per student, such as with report card comments and behavior management
            plans; and{" "}
          </li>
          <li>Assist teachers in managing tasks such as uploading of their rosters</li>
        </ul>
        <p>
          With these AI enhancements, students, teachers, and families will benefit from a more interactive, engaging,
          and responsive educational experience.{" "}
        </p>
        <h2>
          <strong>Our Commitment</strong>
        </h2>
        <p>
          We understand the importance of ensuring strong privacy and security protections for our{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RW61NENJ3NBCWYEA6VJKE">
            students
          </a>{" "}
          , teachers, and families as we integrate new, rapidly changing AI technology, and we want to assure you that
          we will handle your data with the utmost care and responsibility. As part of our ongoing commitment to
          transparency and responsibility in handling your data, we are providing the following information on our new
          ClassDojo AI tools and the choices you have with regard to them.{" "}
        </p>
        <p>
          In addition, ClassDojo supports the Education Technology Industry's{" "}
          <a href="https://edtechprinciples.com/">Principles for AI in Education</a> to ensure safe and responsible use
          of data in AI technology. ClassDojo was deeply involved with the development of the Principles as a member of
          the AI Education Steering Committee. By aligning with these principles, we aim to harness the power of AI
          technology to enrich the learning experience for all our users while upholding the highest standards of
          privacy, security, and ethical conduct. Read more about the Principles for AI in Education{" "}
          <a href="https://edtechprinciples.com/">here</a> that are intended to help guide the industry's development of
          responsible AI that meets the needs of students, educators and families. ClassDojo is also a member of the{" "}
          <a href="https://www.edsafeai.org/?utm_campaign=SIIA%20Education%20Policy%20Updates&amp;utm_medium=email&amp;_hsenc=p2ANqtz-_buuGN0CiK0094I2CrbEpqOnBSka3rHjludwVW4J69FEPtBEKDdMkTewBx7F50sLuyxG-Bx6ptZg-H9gP9q3LHHeCi7jYod5W75942Jy6eD-Q3CzI&amp;_hsmi=304875445&amp;utm_content=304875445&amp;utm_source=hs_email">
            EdSAFE AI
          </a>{" "}
          <a href="https://www.edsafeai.org/industry-council?utm_campaign=SIIA%20Education%20Policy%20Updates&amp;utm_medium=email&amp;_hsenc=p2ANqtz-_NW1wKmiALaBHfeUw7Nsh77GSFO0hRRbEEPbzgbafTlv9Y8fCx3Om7tsttSHQSX24xmMc1nFbxdyYS1KzORenlajXeJ4bHDhrdsvaFI3oIH5EM_is&amp;_hsmi=304875445&amp;utm_content=304875445&amp;utm_source=hs_email">
            Industry Council
          </a>{" "}
          and supports the principles of the{" "}
          <a href="https://www.edsafeai.org/_files/ugd/f384a6_852d90edcf194a7c9836123a6ed9da4a.pdf?utm_campaign=SIIA%20Education%20Policy%20Updates&amp;utm_medium=email&amp;_hsenc=p2ANqtz-9_8moPA5gfNJiYvfn83L1KwyYNPTb1CsE3Zx6rEBMCD2sYiCUU1ERKp7ywzQSurWtqDyqwrIb-ehEeXf3r6D8v1yadq3AwQhFOpmjM9HBSU-eNn7Y&amp;_hsmi=304875445&amp;utm_content=304875445&amp;utm_source=hs_email">
            SAFE Framework
          </a>{" "}
          - Safety, Accountability, Fairness & Efficacy in AI.{" "}
        </p>
        <p>
          As part of our commitments, ClassDojo does not allow OpenAI, Anthropic or any AI third-party{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/">service provider</a> it may utilize, to use
          any information ({" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
            personal information
          </a>{" "}
          or{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
            de-identified
          </a>{" "}
          ) to train, improve, or develop their AI models. ClassDojo also does not use any{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
            personal information
          </a>{" "}
          contained in any Inputs, Prompts, or Output to train any of ClassDojo's AI models.{" "}
        </p>
        <p>This article covers:</p>
        <ul>
          <li>
            <a href="#-how-classdojo-uses-ai-tools-">How ClassDojo Uses AI Tools</a>
          </li>
          <li>
            <a href="#-what-information-is-used-to-provide-the-ai-tools-">
              What Information is Used to Provide the AI Tools
            </a>
          </li>
          <li>
            <a href="#-how-we-work-with-trusted-service-providers-to-offer-the-ai-tools-">
              How We Work With Trusted Service Providers To Offer the AI Tools
            </a>
          </li>
          <li>
            <a href="#-how-can-parents-opt-out-">How Can Parents Opt Out?</a>
          </li>
          <li>
            <a href="#-limitations-of-classdojo-ai-tools-">Limitations of ClassDojo AI Tools</a>
          </li>
          <li>
            <a href="#-how-to-contact-us-">How To Contact Us</a>
          </li>
        </ul>
        <h2>
          <strong>How ClassDojo Uses AI Tools</strong>
        </h2>
        <p>
          Your child's school may choose to utilize certain <a href="https://ai.classdojo.com/">AI classroom tools</a>,
          (e.g., Story Post Writer, Message or Response Writer, or Multiple Choice Assessment Generator) by actively
          engaging with one of these AI tools ("<strong>AI Classroom Tools"</strong>). The teacher will provide certain
          text only content which may contain student{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
            personal information
          </a>{" "}
          ("<strong>Input"</strong>) into the AI Classroom Tools. Additionally, your child's school may choose to
          utilize certain productivity tools not connected to classroom use (e.g. uploading rostering lists), but that
          may contain Student Data ("<strong>AI Productivity Tools</strong>"). Users of AI Productivity Tools may choose
          to provide Inputs that may contain student personal information and may also contain photos or videos (e.g. a
          photo of a classlist of students) and will not be just text-based. The AI Classroom Tools and AI Productivity
          Tools are collectively referred to as "<strong>AI Tools"</strong>.{" "}
        </p>
        <p>
          ClassDojo will then provide a set of instructions or "prompts" (e.g., "write a thank you note") ({" "}
          <strong>"Prompts")</strong> into the AI-provided tool and will then send both the Input and Prompts to
          integrated Large Language Model ("<strong>LLM</strong>") service providers, either{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">OpenAI</a> or{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">Anthropic</a> ("{" "}
          <strong>LLM Service Providers</strong>"). These LLM Service Providers then perform certain AI related
          functions upon the Input in order to produce AI-generated responses on the Input called the "{" "}
          <strong>Output</strong>". The Output is then returned to ClassDojo by the LLM Service Providers. ClassDojo
          then takes this Output and presents it to the teacher in the ClassDojo services to allow for human review.
          Input, Prompt, and Output data processed by the LLM Service Provider is retained only transiently to provide
          the service and for trust and safety purposes; then deleted by the LLM Service Provider immediately upon
          creation of the Outputs.{" "}
        </p>
        <h2>
          <strong>What Information is Used to Provide the AI Tools</strong>
        </h2>
        <p>In serving these new AI Tools, ClassDojo leverages the following information:</p>
        <ul>
          <li>Inputs from teachers, such as text for posts, messages or other contextual text input.</li>
          <li>Prompts or instructions from ClassDojo, such as "Write a thank you note".</li>
          <li>
            Outputs which include AI-generated text suggestions and assessment questions, which are reviewed by teachers
            before being shared with the intended audience.
          </li>
        </ul>
        <p>
          All information provided to the LLM Service Provider is securely transmitted to the LLM Service Provider for
          processing, stored transiently in the United States, and encrypted both in transit and at rest. Our data
          retention policies ensure Inputs, Prompts, and Output are deleted after processing by the LLM Service
          Provider, and that Outputs retained by ClassDojo are safeguarded and stored securely in alignment with
          ClassDojo's existing policies.
        </p>
        <p>
          The following chart provides more detail on the information used to provide the various AI Tools (broken down
          into our AI Classroom Tools and AI Productivity Tools) as well as the processes or "Prompts" performed on the
          "Inputs" to obtain the results or "Output". We will update this chart as we update our features to provide
          schools with the information necessary for them to act as the agent of a parent and provide any necessary{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/210411986-For-teachers-Getting-consent-from-parents">
            consent
          </a>{" "}
          under the Children's Online Privacy Protection Act and its implementing regulations under the Children's
          Online Privacy Protection Rule (collectively "<strong>COPPA"</strong>) or to otherwise approve this use of the
          AI Tools under applicable laws and regulations for any student{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
            personal information
          </a>{" "}
          that is used (for example, under state student privacy laws and the Family Educational Rights and Privacy Act
          ("<strong>FERPA</strong>"). <strong>AI Classroom Tools</strong>{" "}
        </p>
        <p>
          <i>May use Student PII</i>{" "}
        </p>
        <table>
          <tr>
            <th>ClassDojo AI Classroom Tools and Processing Done on Inputs</th>
            <th>User Information Used to Provide the AI Tools (Inputs)</th>
            <th>Output Generated*</th>
            <th>User Types Input is Collected from and Output Disclosed to</th>
            <th>How this Information is provided to the LLM</th>
            <th>Retention Period / Storage / Security</th>
          </tr>
          <tbody>
            <tr>
              <td>
                <strong>Story Post Writer</strong> Helps teachers craft engaging content for ClassDojo Story updates,
                including AI-enhanced narrative suggestions. The AI tool suggests edits, enhances narratives, and
                ensures all posts are aligned with ClassDojo's and individual teacher's communication standards. The
                teacher reviews and edits the suggestions before posting.
              </td>
              <td>
                -Teacher-provided text for the story post which may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                (text only, no photos) -ClassDojo - provided Prompt which will not contain any user information (e.g.
                "Write a post to share with parents in the ClassStory feed"*. The Prompt will include communication
                standards to align with)
              </td>
              <td>
                - AI-enhanced story post suggestions ready for review and use by the teacher. This Output may contain
                student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                (text only, no photos)
              </td>
              <td>
                - Input is collected from teachers - The Output is disclosed to teachers, then once reviewed and
                approved by the teacher, disclosed at the direction of the teacher to students, parents, and any
                ClassDojo user with access to the ClassDojo Story
              </td>
              <td>
                Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                OpenAI or Anthropic for processing.
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes, then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated story posts):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final posts (which will be the Output edited by any human
                    review by the teacher) within the platform for as long as the teacher maintains them in the Class
                    Story, to provide the service, improve the product (as allowed by law), enforce our policies, or to
                    keep our community secure. The Output and final posts are deleted upon direction by the teacher or
                    school, or upon termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage </p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security </p>
                <p>- All inputs and outputs are encrypted in transit and at rest and in transit.</p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Message or Response Writer</strong> This AI tool helps teachers write concise, effective
                messages for communication with parents. It generates text suggestions based on the context provided by
                the teacher, to streamline communication about students learning progress, or logistical matters. The
                teacher reviews and edits the suggestions before sending. All suggestions are aligned with ClassDojo's
                and individual teacher's communication standards.
              </td>
              <td>
                -Teacher-provided text only (no photos). The provided information includes a recipient and message to
                respond with. This provided information may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                . A teacher can optionally include the original message they are responding to. - ClassDojo - provided
                Prompt which will not contain any user information (e.g. "Write a response to an earlier communication,
                which the teacher will provide."* ) The Prompt will include communication standards to align with
              </td>
              <td>
                - AI-generated text suggestions for tailored responses or message drafts for review and use by the
                teacher. This Output may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                (text only, no photos)
              </td>
              <td>
                - Input is collected from teachers. - The Output is disclosed to teachers, then once reviewed and
                approved by the teacher, sent at the direction of the teacher to the recipient (e.g. parent).
              </td>
              <td>
                Text Inputs (including the teacher's initial message or query and additional contextual information) and
                Prompts are securely transmitted by ClassDojo to OpenAI and Anthropic for processing.
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated message):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final messages (which will be the Output edited by any
                    human review by the teacher) within the platform for as long as the teacher maintains the message in
                    order to provide the service, improve the product (as allowed by law), enforce our policies, or to
                    keep our community secure. The Output and final messages are deleted upon direction by the teacher
                    or school, or upon termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>
                  Storage -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security </p>
                <p>- All inputs and outputs are encrypted in transit and at rest and in transit.</p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Thank-You Note Writer</strong> This tool helps teachers with the creation of personalized
                thank-you notes to acknowledge students, parents or staff. The teacher reviews and edits the suggestions
                before sending. All suggestions are aligned with ClassDojo's and individual teacher's communication
                standards.
              </td>
              <td>
                - <i>Details for thank you note</i>: Reasons for gratitude or acknowledgment from the teacher, along
                with a recipient (text only, no photos). This may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                - ClassDojo- provided Prompt which will not contain any user information (e.g. "Generate a thank you
                note that is tailored to the context provided by the teacher"*. The Prompt will include communication
                standards to align with)
              </td>
              <td>
                - AI-generated text suggestions for thank-you notes for review and use by the teacher. This Output may
                contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                (text only, no photos)
              </td>
              <td>
                - Input collected from teachers. - The Output is disclosed to teachers, then once reviewed and approved
                by the teacher, sent at the direction of the teacher to the recipient (e.g. student, parents or staff)
              </td>
              <td>
                - Text Inputs about the reasons for the thank you note and Prompts are securely transmitted by ClassDojo
                to OpenAI and Anthropic for processing.
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final thank-you notes (which is the Output then edited by
                    any human review by the teacher) once the thank-you note is sent by the teacher within the platform
                    for as long as the school directs ClassDojo in order to provide the service, improve the product (as
                    allowed by law), enforce our policies, or to keep our community secure. The Output and final
                    thank-you notes are deleted upon direction by the teacher or school, or upon termination of the
                    contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Report Card Comment Writer</strong> This tool helps teachers write insightful, constructive
                comments on student report cards, personalized to reflect each student's performance. The teacher
                reviews and edits the suggestions before sending. All suggestions are aligned with ClassDojo's and
                individual teacher's communication standards.
              </td>
              <td>
                - <i>Student performance themes:</i> Information provided by the teacher (text only, no photos). This
                may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                - ClassDojo- provided Prompt which will not contain any user information (e.g. "Generate a comment for a
                report card using notes provided by the teacher"*. The Prompt will include communication standards to
                align with)
              </td>
              <td>
                - AI-generated report card comments tailored to individual student achievements and areas for
                improvement, for review and use by the teacher. This Output may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                (text only, no photos)
              </td>
              <td>
                - Input collected from teachers - Output disclosed to teachers, then once reviewed and approved by the
                teacher, sent at the direction of the teacher to students and parents on report cards).
              </td>
              <td>
                - Text Inputs about student performance and Prompts are securely transmitted by ClassDojo to OpenAI and
                Anthropic for processing.
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores the Output and the final comment (which is the Output then edited by any human
                    review by the teacher) once sent by the teacher, for as long as the school directs ClassDojo in
                    order to provide the service, improve the product (as allowed by law), enforce our policies, or to
                    keep our community secure. The Output and final thank-you notes are deleted upon direction by the
                    teacher or school, or upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Parent-Teacher Conference Notes Generator</strong> This tool generates personalized,
                constructive feedback for students during parent-teacher conferences. It processes teacher-provided data
                to create concise, supportive, and growth-focused notes tailored to individual student performance. The
                teacher reviews and edits the suggestions before sending.
              </td>
              <td>
                <i>- Teacher inputs include (text based only):</i> grade level, student name, areas of achievement
                ("Glows"), and areas for improvement ("Grows"). This will contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                Metadata includes language preference inferred from content of the input fields. - ClassDojo- provided
                Prompt which will not contain any user information (e.g. "Generate a comment for a parent-teacher
                conference using notes provided by the teacher"*.
              </td>
              <td>
                - AI-generated parent-teacher conference notes tailored to individual student achievements and areas for
                improvement, with grade-level- appropriate actionable suggestions for parents for review and use by the
                teacher. This Output may contain student{" "}
                <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                  personal information
                </a>{" "}
                (text only, no photos)
              </td>
              <td>
                - Input collected from teachers - Output disclosed to teachers, then once reviewed and approved by the
                teacher, sent at the direction of the teacher to parents.
              </td>
              <td>
                Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                OpenAI and Anthropic for processing.
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final parent-teacher conference notes (which is the Output
                    then edited by any human review by the teacher) once the lesson plan is sent by the teacher within
                    the platform for as long as the school directs ClassDojo in order to provide the service, improve
                    the product (as allowed by law), enforce our policies, or to keep our community secure. The Output
                    and final classroom activity are deleted upon direction by the teacher or school, or upon
                    termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <i>May use Student PII </i>
        </p>
        <table>
          <tr>
            <th>
              <p>
                <strong>ClassDojo AI Classroom Tools and Processing Done on Inputs</strong>
              </p>
            </th>
            <th>
              <p>
                <strong>User Information Used to Provide the AI Tools (Inputs)</strong>
              </p>
              <p> </p>
            </th>
            <th>
              <p>
                <strong>Output Generated* </strong>
              </p>
            </th>
            <th>
              <p>
                <strong>User Types Input is Collected from and Output Disclosed to</strong>
              </p>
            </th>
            <th>
              <p>
                <strong>How this Information is provided to the LLM </strong>
              </p>
            </th>
            <th>
              <p>
                <strong>Retention Period / Storage / Security</strong>
              </p>
            </th>
          </tr>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Story Post Writer</strong>
                </p>
                <p> </p>
                <p>
                  Helps teachers craft engaging content for ClassDojo Story updates, including AI-enhanced narrative
                  suggestions.{" "}
                </p>
                <p>
                  The AI tool suggests edits, enhances narratives, and ensures all posts are aligned with ClassDojo's
                  and individual teacher's communication standards.{" "}
                </p>
                <p>The teacher reviews and edits the suggestions before posting.</p>
              </td>
              <td>
                <p>
                  -Teacher-provided text for the story post which may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (text only, no photos)
                </p>
                <p> </p>
                <p>-ClassDojo - provided Prompt which will not contain any user information </p>
                <p>
                  (e.g. "Write a post to share with parents in the ClassStory feed"*. The Prompt will include
                  communication standards to align with){" "}
                </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>
                  - AI-enhanced story post suggestions ready for review and use by the teacher. This Output may contain
                  student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (text only, no photos)
                </p>
                <p> </p>
              </td>
              <td>
                <p>- Input is collected from teachers</p>
                <p> </p>
                <p>
                  - The Output is disclosed to teachers, then once reviewed and approved by the teacher, disclosed at
                  the direction of the teacher to students, parents, and any ClassDojo user with access to the ClassDojo
                  Story
                </p>
              </td>
              <td>
                <p>
                  Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                  OpenAI or Anthropic for processing.
                </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes, then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated story posts):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final posts (which will be the Output edited by any human
                    review by the teacher) within the platform for as long as the teacher maintains them in the Class
                    Story, to provide the service, improve the product (as allowed by law), enforce our policies, or to
                    keep our community secure. The Output and final posts are deleted upon direction by the teacher or
                    school, or upon termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage </p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security </p>
                <p>- All inputs and outputs are encrypted in transit and at rest and in transit.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Message or Response Writer</strong>
                </p>
                <p> </p>
                <p>This AI tool helps teachers write concise, effective messages for communication with parents. </p>
                <p> </p>
                <p>
                  It generates text suggestions based on the context provided by the teacher, to streamline
                  communication about students learning progress, or logistical matters. The teacher reviews and edits
                  the suggestions before sending.
                </p>
                <p> </p>
                <p>All suggestions are aligned with ClassDojo's and individual teacher's communication standards.</p>
              </td>
              <td>
                <p>
                  -Teacher-provided text only (no photos). The provided information includes a recipient and message to
                  respond with. This provided information{" "}
                </p>
                <p>
                  may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>
                  .{" "}
                </p>
                <p> </p>
                <p>A teacher can optionally include the original message they are responding to.</p>
                <p> </p>
                <p>- ClassDojo - provided Prompt which will not contain any user information </p>
                <p>
                  {" "}
                  (e.g. "Write a response to an earlier communication, which the teacher will provide."* ) The Prompt
                  will include communication standards to align with
                </p>
                <p> </p>
              </td>
              <td>
                <p>
                  - AI-generated text suggestions for tailored responses or message drafts for review and use by the
                  teacher. This Output may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (text only, no photos)
                </p>
                <p> </p>
              </td>
              <td>
                <p>- Input is collected from teachers.</p>
                <p> </p>
                <p>
                  - The Output is disclosed to teachers, then once reviewed and approved by the teacher, sent at the
                  direction of the teacher to the recipient (e.g. parent).
                </p>
              </td>
              <td>
                <p>
                  Text Inputs (including the teacher's initial message or query and additional contextual information)
                  and Prompts are securely transmitted by ClassDojo to OpenAI and Anthropic for processing.
                </p>
                <p> </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated message):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final messages (which will be the Output edited by any
                    human review by the teacher) within the platform for as long as the teacher maintains the message in
                    order to provide the service, improve the product (as allowed by law), enforce our policies, or to
                    keep our community secure. The Output and final messages are deleted upon direction by the teacher
                    or school, or upon termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>
                  Storage -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security </p>
                <p>- All inputs and outputs are encrypted in transit and at rest and in transit.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Thank-You Note Writer</strong>
                </p>
                <p> </p>
                <p>
                  This tool helps teachers with the creation of personalized thank-you notes to acknowledge students,
                  parents or staff. The teacher reviews and edits the suggestions before sending.
                </p>
                <p> </p>
                <p>All suggestions are aligned with ClassDojo's and individual teacher's communication standards.</p>
              </td>
              <td>
                <p>
                  - <i>Details for thank you note</i>: Reasons for gratitude or acknowledgment from the teacher, along
                  with a recipient (text only, no photos). This may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>
                  - ClassDojo- provided Prompt which will not contain any user information (e.g. "Generate a thank you
                  note that is tailored to the context provided by the teacher"*. The Prompt will include communication
                  standards to align with)
                </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>
                  - AI-generated text suggestions for thank-you notes for review and use by the teacher. This Output may
                  contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (text only, no photos)
                </p>
                <p> </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>- Input collected from teachers.</p>
                <p> </p>
                <p>
                  - The Output is disclosed to teachers, then once reviewed and approved by the teacher, sent at the
                  direction of the teacher to the recipient (e.g. student, parents or staff)
                </p>
                <p> </p>
              </td>
              <td>
                <p>
                  - Text Inputs about the reasons for the thank you note and Prompts are securely transmitted by
                  ClassDojo to OpenAI and Anthropic for processing.
                </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final thank-you notes (which is the Output then edited by
                    any human review by the teacher) once the thank-you note is sent by the teacher within the platform
                    for as long as the school directs ClassDojo in order to provide the service, improve the product (as
                    allowed by law), enforce our policies, or to keep our community secure. The Output and final
                    thank-you notes are deleted upon direction by the teacher or school, or upon termination of the
                    contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Report Card Comment Writer</strong>
                </p>
                <p> </p>
                <p>
                  This tool helps teachers write insightful, constructive comments on student report cards, personalized
                  to reflect each student's performance. The teacher reviews and edits the suggestions before sending.
                </p>
                <p> </p>
                <p>All suggestions are aligned with ClassDojo's and individual teacher's communication standards.</p>
              </td>
              <td>
                <p>
                  - <i>Student performance themes:</i> Information provided by the teacher (text only, no photos). This
                  may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>- ClassDojo- provided Prompt which will not contain any user information (e.g. </p>
                <p>
                  "Generate a comment for a report card using notes provided by the teacher"*. The Prompt will include
                  communication standards to align with)
                </p>
                <p> </p>
              </td>
              <td>
                <p>
                  - AI-generated report card comments tailored to individual student achievements and areas for
                  improvement, for review and use by the teacher. This Output may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (text only, no photos)
                </p>
                <p> </p>
              </td>
              <td>
                <p>- Input collected from teachers</p>
                <p> </p>
                <p>
                  - Output disclosed to teachers, then once reviewed and approved by the teacher, sent at the direction
                  of the teacher to students and parents on report cards).
                </p>
              </td>
              <td>
                <p>
                  - Text Inputs about student performance and Prompts are securely transmitted by ClassDojo to OpenAI
                  and Anthropic for processing.
                </p>
                <p> </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores the Output and the final comment (which is the Output then edited by any human
                    review by the teacher) once sent by the teacher, for as long as the school directs ClassDojo in
                    order to provide the service, improve the product (as allowed by law), enforce our policies, or to
                    keep our community secure. The Output and final thank-you notes are deleted upon direction by the
                    teacher or school, or upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Parent-Teacher Conference Notes Generator </strong>
                </p>
                <p> </p>
                <p>
                  This tool generates personalized, constructive feedback for students during parent-teacher
                  conferences.{" "}
                </p>
                <p> </p>
                <p>
                  It processes teacher-provided data to create concise, supportive, and growth-focused notes tailored to
                  individual student performance.
                </p>
                <p> </p>
                <p>The teacher reviews and edits the suggestions before sending.</p>
                <p> </p>
              </td>
              <td>
                <p>
                  <i>- Teacher inputs include (text based only):</i> grade level, student name, areas of achievement
                  ("Glows"), and areas for improvement ("Grows"). This will contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>Metadata includes language preference inferred from content of the input fields.</p>
                <p> </p>
                <p>- ClassDojo- provided Prompt which will not contain any user information (e.g. </p>
                <p>"Generate a comment for a parent-teacher conference using notes provided by the teacher"*. </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>
                  - AI-generated parent-teacher conference notes tailored to individual student achievements and areas
                  for improvement, with grade-level- appropriate actionable suggestions for parents for review and use
                  by the teacher. This Output may contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (text only, no photos)
                </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>- Input collected from teachers</p>
                <p> </p>
                <p>
                  - Output disclosed to teachers, then once reviewed and approved by the teacher, sent at the direction
                  of the teacher to parents.
                </p>
              </td>
              <td>
                <p>
                  Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                  OpenAI and Anthropic for processing.
                </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final parent-teacher conference notes (which is the Output
                    then edited by any human review by the teacher) once the lesson plan is sent by the teacher within
                    the platform for as long as the school directs ClassDojo in order to provide the service, improve
                    the product (as allowed by law), enforce our policies, or to keep our community secure. The Output
                    and final classroom activity are deleted upon direction by the teacher or school, or upon
                    termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h1>Will not use Student PII (unless intentionally inputted by the teacher)</h1>
        <p> </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>ClassDojo AI Classroom Tools and Processing Done on Inputs</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>User Information Used to Provide the AI Tools (Inputs)</strong>
                </p>
                <p> </p>
              </td>
              <td>
                <p>
                  <strong>Output Generated* </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>User Types Input is Collected from and Output Disclosed to</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>How this Information is provided to the LLM </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Retention Period / Storage / Security</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Multiple Choice Assessment Generator</strong>
                </p>
                <p> </p>
                <p>
                  This AI tool assists teachers in creating multiple choice assessments tailored to specific topics or
                  educational standards. It uses input from the teacher to suggest relevant questions for quizzes and
                  tests.{" "}
                </p>
              </td>
              <td>
                <p>
                  -<i>Topic or educational standard</i>: Text - based only Information provided by the teacher. Should
                  not contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>
                  - <i>Desired number of questions:</i> How many questions the teacher needs provided by the AI tool.
                  Text-based only and should not contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>
                  - <i>Grade level provided by the teacher:</i> Text-based only and should not contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>
                  - <i>ClassDojo - provided Prompt</i>: which will not contain any user information{" "}
                </p>
                <p>(e.g. "Create multiple choice questions based on the standard topic provided by the teacher."*)</p>
                <p> </p>
              </td>
              <td>
                <p>- AI-generated suggestions for multiple choice assessments for review and use by the teacher.</p>
              </td>
              <td>
                <p>- Input is collected from teachers.</p>
                <p> </p>
                <p>
                  - The Output is disclosed to teachers, then once reviewed and approved by the teacher, disclosed at
                  the direction of the teacher to recipients of the assessment (e.g. students).
                </p>
              </td>
              <td>
                <p>
                  - Text Inputs about the assessment, the topic and the standards as well as Prompts are securely
                  transmitted by ClassDojo to OpenAI and Anthropic for processing.
                </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of the Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final suggestions (which is the Output then edited by any
                    human review by the teacher) if the teacher decides to use the multiple-choice tool within the
                    platform for as long as the school directs ClassDojo in order to provide the service, improve the
                    product (as allowed by law), enforce our policies, or to keep our community secure. The Output and
                    final questions are deleted upon direction by the teacher or school, or upon termination of the
                    contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Lesson Plan Creator</strong>
                </p>
                <p> </p>
                <p>
                  This tool generates detailed and standards-aligned lesson plans for various grade levels based on
                  teacher inputs. It includes engaging activities, assessments, and<strong> </strong>strategies for
                  differentiation tailored to the specified topic.
                </p>
                <p> </p>
                <p>The teacher reviews and edits the suggestions before sending.</p>
                <p> </p>
              </td>
              <td>
                <p>
                  - <i>Teacher inputs include:</i> grade level and topic/standard (e.g., RL.5.2, Photosynthesis).
                  Metadata includes context for lesson length and topic appropriateness.
                </p>
                <p> </p>
                <p>
                  Text-based only and should not contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>- ClassDojo- provided Prompt which will not contain any user information (e.g. </p>
                <p>
                  "Generate a structured lesson plan using notes provided by the teacher"*. Prompts will also emphasize
                  alignment with curriculum standards, engagement, and inclusivity for diverse classrooms.{" "}
                </p>
                <p> </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>
                  -AI- generated lesson plans for the specified grade and topic, including objectives, materials,
                  activities, assessments, and extension opportunities. Lesson plans are tailored to meet diverse
                  classroom needs.
                </p>
              </td>
              <td>
                <p>- Input is collected from teachers </p>
                <p> </p>
                <p>
                  - The Output is disclosed to teachers, then once reviewed and approved by the teacher, disclosed at
                  the direction of the teacher to students, parents, and any ClassDojo user with proper access.
                </p>
                <p> </p>
                <p> </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>
                  Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                  OpenAI and Anthropic for processing.
                </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final lesson plans (which is the Output then edited by any
                    human review by the teacher) once the lesson plan is sent by the teacher within the platform for as
                    long as the school directs ClassDojo in order to provide the service, improve the product (as
                    allowed by law), enforce our policies, or to keep our community secure. The Output and final lesson
                    plans are deleted upon direction by the teacher or school, or upon termination of the contract,
                    unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Classroom Activity Generator</strong>
                </p>
                <p> </p>
                <p>
                  This tool creates specific, engaging classroom activities tailored to grade levels and provided
                  educational standards. It uses teacher inputs to provide clear, concise, and standards-aligned
                  activity ideas, including step-by-step instructions.
                </p>
                <p> </p>
                <p>The teacher reviews and edits the suggestions before sending.</p>
                <p> </p>
              </td>
              <td>
                <p>
                  <i>- Teacher inputs include: </i>grade level, activity focus (e.g., times tables, supplies, group
                  size), group type (e.g., whole class, small groups), and optional standards (e.g., CCSS, TEKS).{" "}
                </p>
                <p> </p>
                <p>Metadata includes context for classroom suitability.</p>
                <p> </p>
                <p>
                  Text-based only and should not contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p>
                  <i>-ClassDojo Prompt: </i> Will not contain any user information ((e.g.{" "}
                </p>
                <p>"Generate a classroom activity using notes provided by the teacher"*. </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>
                  -AI- generated classroom activities tailored to the provided inputs, including detailed rules and
                  procedures for execution in the classroom. Activities are aligned with provided standards and designed
                  for inclusivity and engagement.
                </p>
              </td>
              <td>
                <p>- Input is collected from teachers </p>
                <p> </p>
                <p>
                  - Output is disclosed to teachers, then once reviewed and approved by the teacher, disclosed at the
                  direction of the teacher to students, parents, and any ClassDojo user with proper access
                </p>
              </td>
              <td>
                <p>
                  Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                  OpenAI and Anthropic for processing.
                </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final Classroom activity (which is the Output then edited
                    by any human review by the teacher) once the lesson plan is sent by the teacher within the platform
                    for as long as the school directs ClassDojo in order to provide the service, improve the product (as
                    allowed by law), enforce our policies, or to keep our community secure. The Output and final
                    classroom activity are deleted upon direction by the teacher or school, or upon termination of the
                    contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Behavior Intervention Plan Generator</strong>
                </p>
                <p> </p>
                <p>
                  This tool generates practical and actionable behavior intervention strategies for teachers to address
                  specific behaviors in their classrooms generally.
                </p>
                <p> </p>
                <p>
                  It uses teacher inputs to create developmentally appropriate, ethical, and culturally sensitive
                  strategies tailored to the specified grade level and behavior (not specific to individual students).
                </p>
                <p> </p>
                <p>The teacher reviews and edits the suggestions before sending.</p>
              </td>
              <td>
                <p>
                  <i>Teacher inputs include:</i> grade level, specific behavior to address (e.g., bullying, staying in
                  seat), and intervention type (e.g., positive reinforcement, de-escalation). Metadata includes context
                  for classroom suitability and the teacher's instructional style.
                </p>
                <p> </p>
                <p>
                  Text-based only and should not contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  (the behavior is not specific to a specified student){" "}
                </p>
                <p>- ClassDojo- provided Prompt which will not contain any user information (e.g. </p>
                <p>"Generate a behavior intervention plan using notes provided by the teacher"*. Prompts will also </p>
                <p>
                  emphasize providing clear, actionable, and ethical behavior intervention strategies and will align
                  with best practices in behavior management.{" "}
                </p>
                <p> </p>
              </td>
              <td>
                <p>AI- generated age-appropriate </p>
                <p>behavior intervention strategies. </p>
                <p> </p>
                <p>Strategies focus on inclusivity, ethical practices, and alignment with intervention goals.</p>
              </td>
              <td>
                <p>- Input is collected from teachers </p>
                <p> </p>
                <p>
                  - Output is disclosed to teachers, then once reviewed and approved by the teacher, disclosed at the
                  direction of the teacher to students, parents, and any ClassDojo user with proper access.
                </p>
              </td>
              <td>
                <p>
                  Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                  OpenAI and Anthropic for processing.
                </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final Classroom activity (which is the Output then edited
                    by any human review by the teacher) once the lesson plan is sent by the teacher within the platform
                    for as long as the school directs ClassDojo in order to provide the service, improve the product (as
                    allowed by law), enforce our policies, or to keep our community secure. The Output and final
                    classroom activity are deleted upon direction by the teacher or school, or upon termination of the
                    contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p> </p>
        <h1>
          <strong>AI Productivity Tools </strong>
        </h1>
        <p> </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>ClassDojo AI Productivity Tools and Processing Done on Inputs</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>User Information Used to Provide the AI Tools (Inputs)</strong>
                </p>
                <p> </p>
              </td>
              <td>
                <p>
                  <strong>Output Generated* </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>User Types Input is Collected from and Output Disclosed to</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>How this Information is provided to the LLM </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Retention Period / Storage / Security</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Class Roster File Analyzer:</strong>
                  <i> </i>
                </p>
                <p> </p>
                <p>
                  This tool processes various file formats (e.g., CSV, JPG, PDF) containing class rosters, consolidates
                  data, and converts it into a standardized JSON format. It extracts names, de-duplicates entries, and
                  validates content based on parsing rules.
                </p>
                <p> </p>
                <p>The teacher reviews and edits.</p>
                <p> </p>
              </td>
              <td>
                <p>
                  <i>- Teacher inputs include (may contain photos):</i>{" "}
                </p>
                <p> </p>
                <p>
                  Teacher-provided class roster files, which may include sensitive student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                  such as names and potential metadata (e.g., timestamps, headers).{" "}
                </p>
                <p> </p>
                <p>Metadata </p>
                <p>includes uploaded file format and processing context.</p>
                <p> </p>
                <p>- ClassDojo- provided Prompt which will not contain any user information (e.g. </p>
                <p>"Generate a class roster"*.</p>
                <p> </p>
              </td>
              <td>
                <p>
                  - AI-generated list of students with deduplicated names and optional additional fields beyond first
                  and last name.
                </p>
                <p> </p>
                <p>
                  This Output will contain student{" "}
                  <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
                    personal information
                  </a>{" "}
                </p>
                <p> </p>
                <p> </p>
              </td>
              <td>
                <p>- Input collected from teachers</p>
                <p> </p>
                <p>
                  - Output disclosed to teachers, then once reviewed and approved used in the Service as directed by the
                  teacher and school.{" "}
                </p>
              </td>
              <td>
                <p>
                  Text Inputs from teachers, along with any specific Prompts, are securely transmitted by ClassDojo to
                  OpenAI and Anthropic for processing.
                </p>
              </td>
              <td>
                <p>Retention Period </p>
                <p>
                  {" "}
                  <i>Inputs and Prompts (</i>Teacher-provided text, context, and ClassDojo provided instructions)
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic : Retained only transiently to provide the service (e.g. the active session) and
                    for trust and safety purposes then deleted immediately upon creation of Outputs.{" "}
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Retained for usage by the teacher, to enforce our policies, improve the product (as
                    allowed by law) or to keep our community secure. Deleted upon direction by teacher or school, or
                    upon termination of the contract, unless we are required by law to retain.
                  </li>
                </ul>
                <p> </p>
                <p>
                  {" "}
                  <i>Outputs</i> (AI generated text suggestions):
                </p>
                <ul>
                  <li>
                    Open AI & Anthropic: Retained only transiently to provide the service and for trust and safety
                    purposes then deleted immediately upon creation of Outputs and transfer of Outputs to ClassDojo.
                  </li>
                </ul>
                <p> </p>
                <ul>
                  <li>
                    ClassDojo: Stores both the Output and the final parent-teacher conference notes (which is the Output
                    then edited by any human review by the teacher) once the lesson plan is sent by the teacher within
                    the platform for as long as the school directs ClassDojo in order to provide the service, improve
                    the product (as allowed by law), enforce our policies, or to keep our community secure. The Output
                    and final classroom activity are deleted upon direction by the teacher or school, or upon
                    termination of the contract, unless we are required by law to retain.{" "}
                  </li>
                </ul>
                <p>Storage</p>
                <p>
                  -<strong> </strong>All data is stored within the United States.
                </p>
                <p> </p>
                <p>Security</p>
                <p>- All inputs and outputs are encrypted in transit and at rest.</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          * The processing/Prompts and Outputs listed are mere examples and are not intended to reflect the exact
          language that will be used.
        </p>
        <h2>
          <strong>How ClassDojo Uses the Information to Provide the AI Tools</strong>
        </h2>
        <p>
          ClassDojo teachers will provide into the ClassDojo AI provided tool, Inputs or content which may contain
          student{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
            personal information
          </a>{" "}
          . Additionally, ClassDojo will provide a set of instructions or "Prompts". ClassDojo will then send both the
          Input and Prompts to an integrated LLM Service Provider (e.g{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">OpenAI</a> or{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">Anthropic</a>), to perform certain AI
          related functions upon the Input in order to produce AI-generated responses on the Input called "Output". To
          protect the privacy of users, ClassDojo does not otherwise share any direct identifiers of students to the LLM
          Service Provider (except to the extent that any direct identifiers are included within the teacher provided
          Input).{" "}
        </p>
        <p>
          Data obtained from the Inputs, Prompts and Outputs may be accessed, processed and used by ClassDojo to
          provide, improve (as allowed by law) and maintain the Service, perform internal analysis (including to provide
          metrics and data logs or activity records needed for measuring up/down time and debugging the tools), and keep
          our platform safe and secure. For example, the Inputs and Prompts will be sent to the LLM Service Provider to
          create the Outputs listed above.{" "}
        </p>
        <p>
          For more information on the "Input" and "Prompts" used to generate the AI insights, the information shared
          with the LLM Service Provider, and ClassDojo's use of AI, please see our table above.{" "}
          <strong>ClassDojo does not use any student </strong>{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_2843be58-eb16-4535-8579-138369598e4d">
            <strong>personal information</strong>
          </a>{" "}
          <strong> contained in any Inputs, Prompts, or Output to train any of ClassDojo's AI models.</strong>{" "}
          <strong>Additionally, ClassDojo does not allow an LLM Service Provider or any AI third-party </strong>{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">
            <strong>service provider</strong>
          </a>{" "}
          <strong>it may utilize to use any information (personal information or </strong>{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_c2fe1e28-a056-42c7-908e-dce30e6f817a">
            <strong>de-identified</strong>
          </a>{" "}
          <strong>) to train, improve or develop their AI models.</strong> This commitment includes the information
          contained in the chart above, such as the teacher provided Inputs, used to produce the AI Outputs.{" "}
        </p>
        <p>
          Your student's data (or your data if you are a teacher) used to provide the "Inputs" ({" "}
          <i>eg: name, school, role, etc</i>) will only be transiently retained by the{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">LLM Service Provider</a> to provide the
          service to ClassDojo. To ensure this, ClassDojo has entered into a specific contract with both{" "}
          <a href="https://privacy.anthropic.com/en/articles/8956058-i-have-a-zero-retention-agreement-with-anthropic-what-products-does-it-apply-to">
            Anthropic
          </a>{" "}
          and OpenAI for "<a href="https://openai.com/enterprise-privacy/">zero data retention</a>" for the ClassDojo
          provided AI-tools mentioned above. This means that the Prompts, Input and Output (a) will not be logged for
          human review by OpenAI or Anthropic and (b) will not be saved to disk or retained by OpenAI or Anthropic.
          Note, however, that OpenAI and Anthropic may perform automated screening of the Inputs and Prompts for safety
          purposes (the "Safety Classifiers"), which shall consist solely of metadata (including classifier types,
          dates, counts and confidence scores) and shall not include any Inputs, Prompts or Output itself (including
          summarizations of Prompts, Inputs or Outputs) or any portion thereof. OpenAI and Anthropic may retain Safety
          Classifiers.{" "}
        </p>
        <h2>
          <strong>How We Work With Trusted Service Providers To Offer the AI Tools</strong>
        </h2>
        <p>
          To deliver these new AI-enhanced tools on the ClassDojo platform, ClassDojo works with trusted service
          providers <a href="https://www.classdojo.com/third-party-service-providers/#">OpenAI</a> and{" "}
          <a href="https://www.classdojo.com/third-party-service-providers/#">Anthropic</a>. In engaging with these
          third party <a href="https://www.classdojo.com/third-party-service-providers/#">service providers</a>,
          ClassDojo remains committed to upholding our values of transparency, accountability, fairness, and safety in
          the use of user data in AI technology.
        </p>
        <p>
          <strong>Figure 1</strong> below details how ClassDojo works with our trusted service providers to provide the
          AI Tools services:
        </p>
        <p>
          <img src="https://static.classdojo.com/img/2025/03/image1.jpg" alt="image1" />
        </p>
        <h2>
          <strong>How Can Parents Opt Out?</strong>
        </h2>
        <p>
          Your child's school may have acted as your agent and consented on your behalf to allow your child access to
          the ClassDojo service as is allowed under COPPA - commonly referred to as "{" "}
          <a href="https://www.ftc.gov/business-guidance/resources/complying-coppa-frequently-asked-questions#N.%20COPPA%20AND%20SCHOOLS">
            school consent
          </a>{" "}
          ." Please read more about this in our{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/210411986-For-teachers-Getting-consent-from-parents">
            FAQ
          </a>{" "}
          . If so, and your child's teacher is allowing your child to use ClassDojo, please contact your child's teacher
          to opt out of having their school or teacher use any of your child's{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RW61NENJ3NBCWYEA6VJKE">
            Student Data
          </a>{" "}
          as Input within the AI Tools.{" "}
        </p>
        <h2>
          <strong>Limitations of ClassDojo AI Tools</strong>
        </h2>
        <p>
          While these AI Tools mentioned above are designed to support and enrich learning, it is crucial to recognize
          that the AI-generated content and solutions provided by our platform are not perfect and are based on and
          limited to the data the AI has been trained on. As a result, there may be instances where the AI's outputs
          might not fully grasp the nuances of a new or complex problem, leading to potential inaccuracies, incorrect or
          misleading information, or misinterpretations.
        </p>
        <p>
          Given the reliance on AI to tailor educational content and provide immediate assistance, we recommend our
          teachers (and other users) critically assess the AI's guidance and verify its accuracy, especially when it
          pertains to complex subjects or advanced topics, and have provided the ability for teachers to provide human
          input on the various AI insights or "Outputs" prior to sharing with{" "}
          <a href="https://classdojo.zendesk.com/hc/en-us/articles/115004741703-Privacy-Policy-and-Terms-of-Service-Key-Terms#h_01GM9RW61NENJ3NBCWYEA6VJKE">
            students
          </a>{" "}
          and families. ClassDojo is committed to reducing the limitations of AI by focusing on responsible AI
          development. This includes ensuring that our AI-powered classroom features are built with privacy, security,
          inclusion, trust, and safety at their core. Our approach to integrating AI into educational tools reflects our
          dedication to providing a supportive, effective, and respectful learning environment for all users.
        </p>
        <h2>
          <strong>How To Contact Us</strong>
        </h2>
        <p>
          Should you have any questions about ClassDojo's use of AI, our privacy or safety practices in the handling of
          your data, or for any other purpose, please contact us by email at privacy@classdojo.com.{" "}
        </p>
        <p>
          You may also write to us at the following address: Attn: Chief Privacy Officer ClassDojo 735 Tehama Street San
          Francisco, California, 94103 United States of America.{" "}
        </p>
      </Container>
    </>
  );
};

export default PageAITransparency;
